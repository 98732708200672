<template>
  <div class="container">
    <b-row class="justify-content-center">
      <b-col md="6" sm="8" class="p-0 m-0">
        <b-card no-body class="p-0 m-0">
          <b-card-body class="p-4">
            <b-form>
              <h1>회원가입</h1>
              <b-input-group class="mt-4 mb-3">
                <b-input-group-prepend>
                  <b-input-group-text>
                    <i class="icon-user"></i>
                  </b-input-group-text>
                </b-input-group-prepend>
                <b-form-input type="text" class="form-control" placeholder="이름" autocomplete="username"
                  v-model="username" />
              </b-input-group>

              <b-input-group class="mb-3">
                <b-input-group-prepend>
                  <b-input-group-text>@</b-input-group-text>
                </b-input-group-prepend>
                <b-form-input type="text" class="form-control" placeholder="이메일" autocomplete="email" v-model="email" />
              </b-input-group>

              <b-input-group class="mb-3">
                <b-input-group-prepend>
                  <b-input-group-text>
                    <i class="icon-lock"></i>
                  </b-input-group-text>
                </b-input-group-prepend>
                <b-form-input type="password" class="form-control" placeholder="비밀번호" autocomplete="new-password"
                  v-model="password" />
              </b-input-group>

              <b-input-group class="mb-4">
                <b-input-group-prepend>
                  <b-input-group-text>
                    <i class="icon-lock"></i>
                  </b-input-group-text>
                </b-input-group-prepend>
                <b-form-input type="password" class="form-control" placeholder="비밀번호 확인" autocomplete="new-password"
                  v-model="passwordConfirm" />
              </b-input-group>

              <b-input-group class="mt-4">
                <b-input-group-prepend>
                  <b-input-group-text>
                    <i class="icon-tag"></i>
                  </b-input-group-text>
                </b-input-group-prepend>
                <b-form-input type="text" class="form-control" placeholder="가입코드" autocomplete="recommend-code"
                  v-model="recommendCode" />
              </b-input-group>
              <b-button class="mt-3" @click.prevent="register" variant="primary" block>계정 생성</b-button>
            </b-form>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import AuthService from '@/services/AuthService'

export default {
  name: 'Register',
  data() {
    return {
      username: '',
      email: '',
      password: '',
      passwordConfirm: '',
      name: '',
      recommendCode: '',
      error: null,
    }
  },
  created() {
    this.recommendCode = this.$route.query.code
  },
  mounted() {
    document.body.classList.remove('bg-white')

    document.body.classList.add('register-mode')
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      document.body.classList.add('mobile')
    }
  },
  destroyed() {
    document.body.classList.remove('register-mode')
  },
  methods: {
    async register() {
      try {
        if (this.password != this.passwordConfirm) {
          alert('비밀번호 확인을 해주세요')
          return
        }
        //this.showSpinner()
        const response = await AuthService.register({
          username: this.username,
          email: this.email,
          password: this.password,
          recommendCode: this.recommendCode,
        })
        window.gtag('event', 'conversion', {
          send_to: 'AW-847545262/rtMgCNPH_YUYEK6HkpQD',
          event_callback: function () { },
        })

        window.dataLayer.push({
          'event': 'sign_up'
        })

        const { token, user } = response.data

        this.$cookie.set('londonsms_token', token, { expires: '1Y', SameSite: 'Lax' })
        this.$store.dispatch('setToken', token)
        this.$store.dispatch('setUser', user)

        alert('회원가입 성공')

        this.$router.push({
          name: 'Home',
        })

        //this.hideSpinner()

      } catch (err) {
        this.hideSpinner()
        this.error = err.response.data.error
      }
    },
  },
}
</script>
